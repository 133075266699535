import React from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import "./style.css";

const SearchField = ({ onSearch, placeholder }) => {
    return (
        <div id="search-container">
            <Input
                className="search-input"
                prefix={<SearchOutlined />}
                placeholder={placeholder?placeholder:"Search..."}
                onChange={onSearch}
            />
        </div>
    );
};

export default SearchField;
