import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";

import "./style.css";
import BannerImg from "components/banner-img";
import { fields } from "./fields";
import { FormContainer, Button, CheckboxField, Loader } from "components";
import { signinAPI } from "services/api-services/auth";
import { saveToken } from "services/local-store";
import { notifError } from "containers/utils";
import { checkLoggedIn } from "utils";
import bannerImg from "assets/images/taxi.png";

const Signin = () => {
  const [showLoading, setShowLoading] = useState(false);
  const [isRememberChecked, setIsRememberChecked] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    let isLoggedIn = checkLoggedIn();
    if (isLoggedIn) navigate("/app");
  }, [navigate]);

  const signinHandler = async (payload) => {
    setShowLoading(true);

    const parsePayload = { ...payload, remember: isRememberChecked };
    const resp = await signinAPI(parsePayload);
    if (resp && resp.status && resp.data) {
      if(resp?.data?.user?.status==1){
        saveToken(resp.data);
        navigate("/app");
      }else {
        setShowLoading(false);
        navigate("/");
        message.error(resp.error || "You are not active at the moment. Please Contact admin.");
      }
  
    } else {
      setShowLoading(false);
      message.error(resp.error || "Something went wrong.");

      notifError(
        "Sign In",
        resp.error || resp.msg?.msg[0] || "Something went wrong."
      );
    }
  };

  const checkHandler = () => {
    setIsRememberChecked((pre) => !pre);
  };

  const customSection = (
    <div className="bottom-section-login">
      <div className="flex-row justify-space">
        <div className="flex-row">
            <CheckboxField
            onSetIsChecked={setIsRememberChecked}
            isChecked={isRememberChecked}
            />
            <div
            className="bottom-section__text primary-color cpointer"
            onClick={checkHandler}
            >
            Remember me
            </div>
        </div>
        {/* <div className="bottom-section__link">
          <Link to="/forgot-password">Forgot password?</Link>
        </div> */}
      </div>

      <div className="m-t-3 ">
        <Button className="signin-btn" onClick={() => {}} labelTxt="Log in" fullWidth={true}/>
      </div>
    </div>
  );

  return (
    <>
      {showLoading && <Loader />}
      <div className="signin-root">
        <div className="signin-root__left-container">
          <div className="signin-container">
            <div className="m-b-3">
              <div className="header">Welcome to Zapp!</div>
              <div className="header-bottom">Please Enter your email address.</div>
            </div>
            <FormContainer
              fields={fields}
              className= "m-b-3"
              onSubmit={signinHandler}
              initialValues={{}}
              renderCustomSubmit={customSection}
            />
          </div>
        </div>
        <div className="signin-root__right-container">
          <BannerImg><img src={bannerImg}/></BannerImg>
        </div>
      </div>
    </>
  );
};

export default Signin;
