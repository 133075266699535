import * as React from "react";
import "./style.css";

const ImageUploader = (props) => {
  const { form, field, create_err_msg, labelText, update = false } = props;

  const [fileName, setFilename] = React.useState("");

  const handleChange = (e) => {
    let fiels = e.target.files;
    let fileReader = new FileReader();
    fileReader.readAsDataURL(fiels[0]);
    fileReader.onload = (e) => {
      if (e.target && e.target.result) {
        setFilename(e.target.result);
        form.setFieldValue(field.name, e.target.result);
      }
    };
  };

  return (
    <div className="files" id="files">
      {labelText && <div className="label-text">{labelText}</div>}
      <div className="files-upload">
        <input type="file" onChange={(e) => handleChange(e)} accept="image/*" />
        <div className="files-type">
          {fileName ? (
            <>
              <div className="type-type-image">
                <img src={fileName} alt="" />
              </div>
            </>
          ) : (
            <>
              {update && field.value ? (
                <>
                  <div className="img-container">
                    <img src={field.value} alt="Image" className="img" />
                  </div>
                </>
              ) : (
                <>
                  <span> Click here to choose and upload</span>
                  <span>Supported formats PNG, JPEG, JPG</span>
                </>
              )}
            </>
          )}
        </div>
      </div>
      {form && form.errors[field.name] && form.touched[field.name] && (
        <div className="error-text">
          {create_err_msg
            ? create_err_msg(field.value)
            : form.errors[field.name]}
        </div>
      )}
      {create_err_msg &&
        !create_err_msg(field.value) &&
        field.name === "cpassword" && (
          <div className="error-text">{form.errors[field.name]}</div>
        )}
    </div>
  );
};

export default ImageUploader;
