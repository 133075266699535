import { InputField } from "../../../components";

const fields = [
    {
        name: "email",
        // placeholder: "Email Address",
        type: "email",
        required: true,
        component: InputField,
        label: "Email Address",
        width:24,
    },
    {
        name: "password",
        // placeholder: "Password",
        type: "password",
        required: true,
        component: InputField,
        label: "Password",
        width:24,
    },
];

export { fields };
