import React from "react";
import { Link } from "react-router-dom";

const VerifySuccess = () => {
    return (
        <div className="success-root">
            <div className="success-container">
                <div>
                    <h2 className="success-container-heading">
                        Your account has been verified successfully. You can now
                        <span>
                            <Link to="/"> Sign In</Link>
                        </span>
                    </h2>
                </div>
            </div>
        </div>
    );
};

export default VerifySuccess;
