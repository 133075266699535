import React, { useEffect, useState } from "react";
import { Select } from "antd";

import "./style.css";

const { Option } = Select;
const SelectField = ({
  defaultSelectedValue,
  isFormik = true,
  id = "dropdown-0",
  suffixIcon,
  onChange,
  placeholder,
  form,
  field,
  labelText,
  options,
  variant,
  ...rest
}) => {
  return (
    <>
      <div className="select-root">
        <div className="label-text">{labelText}</div>
        <Select
          placeholder={placeholder}
          style={{ width: "100%" }}
          onBlur={form.handleBlur(field.name)}
          onChange={form.handleChange(field.name)}
          value={field.value || null}
          // suffixIcon={
          //     <img src={ArrowDown} alt="edit" className="icon" />
          // }
        >
          {options &&
            options.length > 0 &&
            options.map((option) => (
              <Option key={option.id}>{option.label}</Option>
            ))}
        </Select>
      </div>

      {form && form.errors[field.name] && form.touched[field.name] && (
        <div className="error-text">
          {rest.create_err_msg
            ? rest.create_err_msg(field.value)
            : form.errors[field.name]}
        </div>
      )}
    </>
  );
};

export default SelectField;
