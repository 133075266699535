import React from "react";

const VerifyError = () => {
    return (
        <div className="success-root">
            <div className="success-container">
                <div className="m-b-5">
                    <h2 className="success-container-heading">Link expired.</h2>
                </div>
            </div>
        </div>
    );
};

export default VerifyError;
