import React from "react";
import "./style.css";

const BannerImg = () => {
  return (
    <div className="bannerimg-container">
    </div>
  );
};

export default BannerImg;
