import React from "react";
import "./index.css";
import { FloatLabel } from "components"
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const PhoneField = ({
    form,
    field,
    label,
    create_err_msg,
    placeholder,
    disabled,
    labelText,
    className,
    ...rest
}) => {
    const handleChange = (value) =>{
      form.setFieldValue(field.name, value);
    }

    return (
      <>
        {labelText && <div className={`label-text`}>{labelText}</div>}
        <FloatLabel label={label ? label : ""} value={field.value}>
            <PhoneInput
              value={field.value}
              {...rest}
              {...field}
              placeholder={placeholder}
              onChange={handleChange}
              inputStyle={{ fontSize: "12px",marginLeft: "5px" }}
              onlyCountries={["be", "nl", "de" ]}
              enableLongNumbers
              enableSearch
            />
        </FloatLabel>

        {(form && form.errors[field.name] && form.touched[field.name])||(form.isValid === false && form.errors[field.name]) && (
          <div className="error-text">
            {create_err_msg
              ? create_err_msg(field.name)
              : form.errors[field.name]}
          </div>
        )}
      </>
    );
};

export default PhoneField;
