import { Radio } from 'antd';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';

const RadioComponent = ({ form, field, labelText, options, className,...rest}) => {
  
  const handleChange = (event) => {
    form.setFieldValue(field.name, event.target.value)
  }
  return (
    <>
      {labelText && <span style={{ marginRight: "2rem" }}>{labelText}</span>}
      <Radio.Group className={className} options={options} onChange={handleChange} value={field.value} />
    </>
  );
};

export default RadioComponent;