import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import "./index.css";
import moment from "moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { Button, Input } from "antd";
import { FloatLabel } from "components";
import { useMediaQuery } from "react-responsive";

export default memo(
  ({
    form,
    field,
    label,
    options,
    variant,
    style,
    className,
    isReturn = false,
    ...rest
  }) => {
    const isDesktopOrLaptop = useMediaQuery({
      query: "(min-width: 556px)",
    });
    const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
    const [time, setTime] = useState(
      isReturn
        ? form.values["date"]
          ? moment(form.values["date"]).add(30, "minutes").format("HH:mm")
          : moment().add(30, "minutes").format("HH:mm")
        : moment().format("HH:mm")
    );
    const [value, setValue] = useState("");
    const [visible, setVisible] = useState(false);
    const [dateTime, setDateTime] = useState();

    useEffect(() => {
      if (form.values[field.name])
        setTime(
          isReturn
            ? form.values["date"]
              ? moment(form.values["date"]).add(30, "minutes").format("HH:mm")
              : moment().add(30, "minutes").format("HH:mm")
            : moment().format("HH:mm")
        );
    }, [field.value]);

    function valid(current) {
      let yesterday = moment().subtract(1, "day");
      return current.isAfter(yesterday);
    }

    const onchangehandler = (current) => {
      setTime(current.format("HH:mm"));
    };

    const onDateChangehandler = (current) => {
      if (isDesktopOrLaptop) setDate(current.format("YYYY-MM-DD"));
      else setDateTime(current.format("YYYY-MM-DD HH:mm"));
    };

    const selectDate = () => {
      let recDate = date + " " + time;
      if (isDesktopOrLaptop) form.setFieldValue(field.name, recDate);
      else
        form.setFieldValue(
          field.name,
          dateTime
            ? dateTime
            : isReturn
            ? moment().add(30, "minutes").format("YYYY-MM-DD HH:mm")
            : moment().format("YYYY-MM-DD HH:mm")
        );
      setVisible(false);
    };

    const selectNowDate = () => {
      form.setFieldValue(
        field.name,
        isReturn
          ? form.values["date"]
            ? moment(form.values["date"])
                .add(30, "minutes")
                .format("YYYY-MM-DD HH:mm")
            : moment().add(30, "minutes").format("YYYY-MM-DD HH:mm")
          : moment().format("YYYY-MM-DD HH:mm")
      );
      if (visible === true) {
        setVisible(false);
      }
    };
    const suffix =
      value === "" ? (
        <div onClick={selectNowDate} className="now-text">
          Now
        </div>
      ) : null;

    const cancelDate = () => {
      form.setFieldValue(field.name, "");
      setVisible(false);
    };

    const content = (
      <div className="rdt-picker">
        <header className="rdp-head">Select Date and time</header>
        <div
          className={
            isDesktopOrLaptop ? "rdp-wrapper" : "rdp-wrapper-responsive"
          }
        >
          <div>
            {isDesktopOrLaptop && (
              <>
                <Input
                  className="rdtp-input"
                  value={date + " " + time ?? ""}
                  readOnly={true}
                />
                <Datetime
                  className="rdt-bg"
                  initialViewDate={
                    isReturn
                      ? form.values["date"]
                        ? moment(form.values["date"]).add(30, "minutes")
                        : moment().add(30, "minutes")
                      : moment()
                  }
                  timeFormat={"HH mm"}
                  dateFormat={false}
                  input={false}
                  onChange={onchangehandler}
                />
              </>
            )}
            <div className="btm-btn">
              <Button onClick={selectDate} className="rdp-select">
                Select
              </Button>
              <Button onClick={cancelDate} className="rdp-select">
                Cancel
              </Button>
            </div>
          </div>
          {isDesktopOrLaptop && <hr className="hr" />}

          <Datetime
            className="rdt-bg"
            input={false}
            onChange={onDateChangehandler}
            timeFormat={isDesktopOrLaptop ? false : "HH:mm"}
            initialViewDate={
              isReturn
                ? form.values["date"]
                  ? moment(form.values["date"]).add(30, "minutes")
                  : moment().add(30, "minutes")
                : moment()
            }
            isValidDate={valid}
          />
        </div>
      </div>
    );

    return (
      <div className={className}>
        <FloatLabel label={label} value={field.value}>
          <Input
            className="rdp-input"
            value={form.values[field.name]}
            suffix={suffix}
            onClick={() => setVisible(!visible)}
            readOnly={true}
          />
        </FloatLabel>
        {visible && content}
        {form && form.errors[field.name] && form.touched[field.name] && (
          <div className="error-text">{form.errors[field.name]}</div>
        )}
      </div>
    );
  }
);
