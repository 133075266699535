import React from "react";
import { Input } from "antd";
import "./style.css";
import { FloatLabel } from "components"
const InputField = ({
    form,
    field,
    label,
    create_err_msg,
    placeholder,
    disable,
    labelText,
    className,
    ...rest
}) => {
    return (
      <>
        {labelText && <div className={`label-text`}>{labelText}</div>}
        <FloatLabel label={label ? label : ""} value={field.value}>
          {rest.type === "password" ? (
            <Input.Password
              {...rest}
              {...field}
              value={field.value}
              autoComplete="off"
              className="input"
              placeholder={placeholder}
              disabled={disable}
            />
          ) : rest.type === "textarea" ? (
            <Input.TextArea
              {...rest}
              {...field}
              value={field.value}
              autoComplete="off"
              className={className ? className : "input"}
              placeholder={placeholder}
              disabled={disable}
              showCount
              maxLength={50}
            />
          ) : (
            <Input
              {...rest}
              {...field}
              value={field.value}
              autoComplete="off"
              className={className ? className : "input"}
              placeholder={placeholder}
              disabled={disable}
            />
          )}
        </FloatLabel>

        {(form && form.errors[field.name] || form.touched[field.name]) && (
          <div className="error-text">
            {create_err_msg
              ? create_err_msg(field.value)
              : form.errors[field.name]}
          </div>
        )}
      </>
    );
};

export default InputField;
