import Request from "../request";

export const getAdminProfileAPI = () => {
    const resp = Request({
        method: "GET",
        api: "hotel/profile",
        token: true,
    });

    return resp;
};

export const updateAdminProfileAPI = (data, lang) => {
    const resp = Request({
        method: "PUT",
        data,
        api: "hotel/profile",
        token: true,
        lang
    });

    return resp;
};

export const resetPasswordAPI = (data,lang) => {
    const resp = Request({
        method: "PUT",
        data,
        api: "hotel/change-password",
        token: true,
        lang
    });

    return resp;
};
