export const saveToken = (value) => {
    localStorage.setItem("token", JSON.stringify(value));
};

export const getToken = () => {
    let token = getItem("token");
    if (token) return token.access_token;
    else return null;
};

export const deleteToken = () => {
    localStorage.removeItem("token");
};

export const clearStorage = () => {
    localStorage.clear();
};

export const setItem = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const getItem = (key) => {
    let value = localStorage.getItem(key);
    if (value) return JSON.parse(value);
    return null;
};

export const removeItem = (key) => {
    localStorage.removeItem(key);
};
