import React from "react";
import { Checkbox } from "antd";

const CheckboxField = ({ onSetIsChecked, isChecked }) => {
    const onChange = (e) => {
        onSetIsChecked(e.target.checked);
    };

    return (
        <div>
            <Checkbox onChange={onChange} checked={isChecked}></Checkbox>
        </div>
    );
};

export default CheckboxField;
