import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import "./style.css";

const antIcon = <LoadingOutlined style={{ fontSize: 100 }} spin />;

const Loader = () => {
    return (
        <div className="loader__backdrop-container">
            <div className="loader">
                <Spin indicator={antIcon} />
            </div>
        </div>
    );
};

export default Loader;
