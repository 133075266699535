import React, { createContext, useReducer } from "react";

const initialState = {
  hotelProfile: "",
};

const HotelContext = createContext({
  ...initialState,
});

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_HOTEL_PROFILE":
      return {
        ...state,
        hotelProfile: action.payload,
      };
  }
};

export const HotelContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setHotelProfile = (payload) => {
    dispatch({
      type: "SET_HOTEL_PROFILE",
      payload,
    });
  };


  return (
    <HotelContext.Provider
      value={{
        ...state,
        setHotelProfile,
      }}
    >
      {children}
    </HotelContext.Provider>
  );
};

export default HotelContext;
