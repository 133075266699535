import axios from "axios";
import { errorHandler } from "./error-handler";
import { getToken } from "services/local-store";

const BASE_URL = process.env.REACT_APP_BASEURL + "api/";

const getHeader = async (status, api, lang) => {
  const header = {
    "Content-Type": "application/json",
    "Accept": "application/json",
    "Accept-Language": lang,
  };

  if (status) {
    const token = await getToken();
    if (token) header["Authorization"] = `Bearer ${token}`;
  }

  return header;
};

const Request = ({ method, api, data, token, lang }) => {
  return new Promise(async (resolve, reject) => {
    const headers = await getHeader(token, api, lang);
    const url = `${BASE_URL}${api}`;
    try {
      axios({ method, url, data, headers })
        .then((response) => {
          if (response && response.data && "status" in response.data)
            resolve(response.data);
          resolve({ status: true, data: response.data });
        })
        .catch((error) => {
          resolve(errorHandler(error));
        });
    } catch (error) {
      resolve(errorHandler(error));
    }
  });
};

export default Request;
