import "./style.scss";
import React, {useState} from 'react'
const Card = ({ children,selected ,onClick}) => {
  return (
    <div id="card" onClick={onClick} className={selected ?"selected": ""}>
      {children}
    </div>
  );
};

export default Card;
