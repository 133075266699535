import {
    verifyPassword,
    password_conditions,
} from "components/form-components/utils";
import { InputField } from "components";

const fields = [
    {
        name: "password",
        placeholder: "New Password",
        type: "password",
        labelText: "New Password",
        required: true,
        component: InputField,
        matches: password_conditions,
        create_err_msg: verifyPassword,
    },
    {
        name: "cpassword",
        placeholder: "Confirm New Password",
        type: "password",
        labelText: "Confirm New Password",
        required: true,
        component: InputField,
        matches: password_conditions,
        create_err_msg: verifyPassword,
    },
];

export { fields };
