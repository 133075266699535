import React, { useState, useCallback, useEffect, memo } from "react"
import { Button, InputNumber } from "antd"
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import { FloatLabel } from "components"
import "./style.css"
export default memo(({ form,
  field,
  label,
  create_err_msg,
  placeholder,
  disabled,
  labelText,
  className,
  min,
  max,  ...rest }) => {

  return (
    <>
    <FloatLabel label={label ? label : ""} value={field.value||1}>
     <InputNumber
        min={min}
        max={max}
        className={className}
        defaultValue={1}
        onChange={(val=1) => {
          form.setFieldValue(field.name, val);
        }}
        controls={{upIcon: <div style={{fontSize:"1.6rem"}}>+</div>,downIcon:<div style={{fontSize:"1.6rem"}}>-</div>}}
        {...rest}
      />
   </FloatLabel>
    {form && form.errors[field.name] && form.touched[field.name] && (
      <div className="error-text">{form.errors[field.name]}</div>
    )}
  </>
  )
})
