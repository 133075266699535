import { lazy } from "react"
const UpComingRides = lazy(()=>import("./upcoming-ride"));
const RideHistory = lazy(() =>import("./ride-history"))
const BookTaxi = lazy(() =>import("./book-taxi"))
const WaitCard = lazy(() =>import("./wait-card"))
const DriverDetail = lazy(() =>import("./driver-detail"))
const View = lazy(() =>import("./ride-history/view/view"))
const ViewUpComing = lazy(() =>import("./upcoming-ride/view"))
const CalculatePrice = lazy(() =>import("./calculate-price"))
const AdminProfile = lazy(() =>import("./admin-profile"))
const UpComingParkAndFlyRides = lazy(()=>import("./upcoming-park-and-fly-ride"));
const ViewUpComingParkAndFly = lazy(() =>import("./upcoming-park-and-fly-ride/view"))
const ParkAndFly = lazy(() =>import("./park-and-fly-history"))
const ViewParkAndFly = lazy(() =>import("./park-and-fly-history/view-park-and-fly-history"));
const FlyByRideBook = lazy(() => import("./fly-by-module/stepper"))
export {
  UpComingRides,
  RideHistory,
  BookTaxi,
  WaitCard,
  DriverDetail,
  View,
  ViewUpComing,
  CalculatePrice,
  AdminProfile,
  ParkAndFly,
  UpComingParkAndFlyRides,
  ViewUpComingParkAndFly,
  ViewParkAndFly,
  FlyByRideBook
};
