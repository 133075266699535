import moment from "moment";
import { getToken, deleteToken } from "services/local-store";
import React, { useState } from "react";
import { date } from "yup";
export const checkLoggedIn = () => {
  let token = getToken();
  if (token) return true;
  else return false;
};

export const logoutHandler = () => {
  deleteToken();
  window.location.href = "/";
};

export const responseParser = (res) => {
  if (res && res.status) return res.data;
  return [];
};

export const respParser = (res) => {
  if (res && res.status) {
    const data = res?.data?.data.map((row) => {
      const date = moment(row.ride_time).format("YYYY-MM-DD, HH:mm a");
      // let customerName = row.customer.first_name+" "+row.customer.last_name
      return {
        ref_no: row.ref_no,
        booking_id: row.booking_id,
        origin: row.origin,
        destination: row.destination,
        // customer_name: `${row?.customer?.first_name} ${row?.customer?.last_name}`,
        customer: row?.customer
          ? row?.customer?.last_name
            ? typeof row?.customer?.first_name === "string" &&
              row?.customer?.first_name.concat(" ", row?.customer?.last_name)
            : row?.customer?.first_name
          : "-",
        customerPhone: row?.customer?.phone_no
          ? row?.customer?.phone_no.toString()
          : "-",
        passengers: row.passengers,
        driver: row?.driver
          ? row?.driver?.last_name
            ? typeof row?.driver?.first_name === "string" &&
              row?.driver?.first_name.concat(" ", row?.driver?.last_name)
            : row?.driver?.first_name
          : "-",
        driverPhone: row?.driver?.phone_no
          ? row?.driver?.phone_no.toString()
          : "-",
        ride_time: date,
        status: row.status,
        price: row.price ? row.price : "-",
        created_at: row.created_at,
        estimatedTime: row?.eta?.estimatedTime,
        payment_mode: row?.payment_mode ? row?.payment_mode : "CASH",
        id: row.id,
      };
    });
    return data;
  }
  return [];
};
export const resParser = (res) => {
  if (res && res.status) {
    const data = res?.data?.data.map((row) => ({
      origin_house: row.origin_house ? row.origin_house : "-",
      driver: row?.driver?.first_name,
      ride_time: row.pickedup_time? moment(row.pickedup_time).format("YYYY-MM-DD, HH:mm a"):"-",
      status: row.status,
      price: row.price ? row.price : "-",
      created_at: moment(row.created_at).format("YYYY-MM-DD, HH:mm a"),
      estimatedTime:row?.eta ? row?.eta?.estimatedTime?.text: "-",
      payment_mode: row?.payment_mode ? row?.payment_mode : "CASH",
      id: row.id,
    }));
    return data;
  }
  return [];
};

export const DateTimeFormat = (data) => {
  const date = moment(data.dateTime).format("YYYY-MM-DD, HH:mm a");
  return <span>{date}</span>;
};

export const parsePayload = (parse) => {
  let parseValue = parse
    ? {
        start: parse && parse[0],
        end: parse && parse[1],
      }
    : "";
  return parseValue;
};

export const parser = (data) => {
  let date = data?.payload?.date;
  let parse = {
    // userId: data?.data?.id,
    taxi_type_id: data.id,
    ride_time: date ? moment(date) : moment(),
    origin: {
      lat: data?.from?.lat,
      lng: data?.from?.lng,
      address: data?.from?.name,
    },
    origin_house: data?.payload?.room
      ? `Room No. ${data?.payload?.room}`
      : null,
    note: data?.payload?.note,
    is_scheduled: date? (moment(date) < moment().add(30, "minutes") ? false : true): false,
    // payment_mode: "Cash"
    // origin_lat:data.center? data.center[0]: null,
    // origin_lng: data.center? data.center[1]: null
  };
  return parse;
};

export const parseLocation = (value) => {
  let parseVal = {
    source_lat: value?.from?.lat,
    source_lng: value?.from?.lng,
    destination_lat: value?.dest?.lat,
    destination_lng: value?.dest?.lng,
  };
  return parseVal;
};

export const parserCalculatedFare = (value) => {
  const { payload, card, locations} = value;
  const fromLoc = locations?.from, destLoc = locations?.dest
  return {
    origin_house: payload?.room ? `Room No. ${payload?.room}` : null,
    origin: {
      lat: fromLoc?.lat,
      lng: fromLoc?.lng,
      address: fromLoc?.name,
    },
    destination: {
      lat: destLoc?.lat,
      lng: destLoc?.lng,
      address: destLoc?.name,
    },
    taxi_type_id: card?.id,
    ride_time: payload.date? moment(payload.date): moment(),
    note: payload?.note ? payload?.note : "",
    payment_mode: payload?.paymentmode,
    is_scheduled: payload.date
      ? moment(payload.date) < moment().add(30, "minutes")
        ? false
        : true
      : false,
    // is_scheduled: moment(payload.date) < moment().add(30, "minutes") ? false : true,
    return_ride: payload.returnDate ? true : false,
    return_time: payload?.returnDate ? moment(payload.returnDate) : "",
    price: card?.netFare,
  };
};

function parseRideData(data) {
    const {
      info,
      user,
      from,
      dest,
      selectedCard,
      hotelId,
      distance
    } = data || {};
    const {paymentmode, passenger, date, isReturn, returnDate} = info
    const {first_name, last_name, user_email, phone, Ref_id, note } = user
    const {id, name, image, detail, meterFare, tax, netFare, seats, time} = selectedCard

    return {
        hotel_id: hotelId,
        origin_house:null,
        origin: {
            lat: from?.lat,
            lng: from?.lng,
            address: from?.name,
        },
        destination: {
            lat: dest?.lat,
            lng: dest?.lng,
            address: dest?.name,
        },
        ride_time: date  ? moment(date) : moment(),
        price: netFare,
        taxi_type_id: id,
        is_scheduled:date? (moment(date) < moment().add(30, "minutes")? false: true): false,
        return_ride: isReturn && returnDate ? true : false,
        return_time: isReturn && returnDate ? moment(returnDate): null,
        distance: distance,
        passengers:passenger,
        payment_mode: paymentmode,
        payment_type: "FIXED",
        note: note,
        guest: {
            first_name,
            last_name,
            user_email,
            phone_no: "+" + phone,
        },
        ref_no: Ref_id,
        // default_lang: locCode
    };
}

export const upcomingParkFlyParser = (res) => {
  if (res && res.status) {
    const data = res?.data?.data.map((row) => ({
      ref_no: row.ref_no ? row.ref_no : "-",
      origin: row.origin ? row.origin : "-",
      destination:row?.destination,
      ride_time: row.pickedup_time? moment(row.pickedup_time).format("YYYY-MM-DD, HH:mm a"):"-",
      created_time:row.ride_time? moment(row.ride_time).format("YYYY-MM-DD, HH:mm a"):"-",
      payment_mode: row.payment_mode ? row.payment_mode : "-",
      driver: row?.driver?.first_name,
      customer_name: `${row?.customer?.first_name || '-'} ${row?.customer?.last_name || '-'}`,
      price: row.price ? row.price : "-",
      status: row.status,
      payment_mode: row?.payment_mode ? row?.payment_mode : "CASH",
      // created_at: moment(row.created_at).format("YYYY-MM-DD, HH:mm a"),
      // estimatedTime:row?.eta ? row?.eta?.estimatedTime?.text: "-",
      id: row.id,
      phone_no: row?.customer?.phone_no || '-',
    }));
    return data;
  }
  return [];
};

export { parseRideData };