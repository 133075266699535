import "./style.css";
import { useState, useRef, useEffect, memo,useContext } from "react";
import { Input, List, Typography } from "antd";
import FloatLabel from "components/float-label";
import { useFormikContext } from "formik";
import { HotelContext } from "context";
const ACCESS_TOKEN =
    "pk.eyJ1IjoiYW5hbmRzaGFtIiwiYSI6ImNrb3RnYnI2dTBhejAyeG13NnFocXU0dXMifQ.z3oL4ykm-whw_IkslVR13g",
  BASE_URL = "https://api.mapbox.com/geocoding/v5/mapbox.places/";


  const getBoundValue = (data) => {
    if (data) {
      return {
        // bbox: data?.bound_box ? data?.bound_box.split(",") : null,
        center: [data.lng, data.lat],
      };
    }
    return null;
  };
var preventApis = false;
var previousvalue = "";
const LocationInput = ({
  placeholder,
  // onChange,
  addBtn,
  onAdd,
  form,
  field,
  label,
  // bbox,
  disabled,
  initialValue,
  labelText,
  className,
  switchComponent=()=>{},
  showBtn=()=>{},
  create_err_msg,
  Region = false,
  ...rest
}) => {
  let bbox = [2,47,17,55];
  const { values, submitForm } = useFormikContext();
  const { hotelProfile } = useContext(HotelContext);
  const [inputValue, setInputValue] = useState(
    form && field ? form.values[field.name] : initialValue || null
  );
  const [features, setFeatures] = useState([]);
  const inputRef = useRef();

  useEffect(() => {
    if(!form.values[field.name]){ 
      setInputValue(null);
      showBtn(false);
    }
    if(field.name=="from"){
      setInputValue(values["from"].name);
      submitForm();
    }
  }, [form.values])

  useEffect(()=>{
    if(!inputValue){
      form.setFieldValue("dest", "");
      submitForm();
    }
  },[inputValue])

  useEffect(()=>{
    if(field.name=="from"){
      setInputValue(values["from"].name);
    }
    if(field.name=="dest"){
      setInputValue(values["dest"].name);
    }
  },[form.values["interChange"]])

  useEffect(()=>{
    switchComponent(form.values["switchRide"]);
  },[values?.switchRide])

  // if (hotelProfile?.default_lang=="en") bbox = [ 2.4913024, 49.496968, 6.408152, 51.505115 ]
  // if (hotelProfile?.default_lang=="nl") bbox = [ 3.3363182, 50.750367, 7.227499, 53.6392176 ]
  // if (hotelProfile?.default_lang=="de") bbox = [ 5.866315, 47.270238, 15.041832, 55.1286491 ]

  const searchRegion = (query) => {
    let url = null;
    let proximity = getBoundValue(hotelProfile)
    if (preventApis) return (preventApis = false);
    if (!query) return setFeatures([]);

    const radius = bbox ? "&bbox=" + bbox : "";
    if (process.env.NODE_ENV == 'production'){
      url =`${BASE_URL}${query}.json?proximity=${proximity?.center}&access_token=${ACCESS_TOKEN}&limit=4` + radius;
    } else {
      url = `${BASE_URL}${query}.json?&access_token=${ACCESS_TOKEN}&limit=4`;
    }
    fetch(url)
      .then((res) => res.json())
      .then((res) => {
        setFeatures(res?.features || []);
      })
      .catch((err) => {
      });
  };
 
  const onInputChange = (evt) => {
    setInputValue(evt.target.value);
    searchRegion(evt.target.value);
  };

  const onSelect = (feature) => {
    let info = {
      id: feature?.id,
      name: feature?.place_name,
      lat: feature?.center[1],
      lng: feature?.center[0],
    };
    preventApis = true;
    setInputValue(feature.place_name);
    form.setFieldValue(field.name, info)
    setFeatures([]);
    if(field.name == "dest") return  showBtn(true);
    if(form?.values["from"]==null||undefined && form?.values["dest"]==null||undefined) submitForm();
    previousvalue = feature.place_name;
  }
  return (
    <div id="place-picker">
      <div className={`label-text`}>{labelText}</div>
      {/* <Input.Group compact> */}
        <FloatLabel label={label} value={inputValue}>
          <Input
            size="large"
            placeholder={placeholder}
            className={`ant-select-selection-search-input ${
              className && className
            }`}
            onChange={onInputChange}
            autoFocus
            ref={inputRef}
            value={inputValue}
            onBlur={() => {
              if (!inputValue && !addBtn) {
                preventApis = true;
                setFeatures([]);
              }
            }}
            // disabled={!form.errors? false: disabled}
          />
        </FloatLabel>

      {Boolean(features.length) && (
        <List
          bordered
          className="list-style"
          dataSource={features}
          renderItem={(feature) => (
            <List.Item key={feature.id} onClick={() => onSelect(feature)}>
              <Typography.Text>{feature.place_name}</Typography.Text>
            </List.Item>
          )}
        />
      )}
    {form && form.errors[field.name] && form.touched[field.name] && (
      <div className={`error-text ${className && className}`}>
        {create_err_msg
          ? create_err_msg(field.value)
          : form.errors[field.name]}
      </div>
    )}
    </div>
  );
};
export default memo(LocationInput);
