import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";

import "./style.css";
import BannerImg from "components/banner-img";
import { fields } from "./fields";
import { FormContainer, Button, Loader } from "components";
import { resetPasswordAPI } from "services/api-services/auth";
import { notifError, notifSuccess } from "containers/utils";

const ResetPassword = (props) => {
    const [showLoading, setShowLoading] = useState(false);

    let navigate = useNavigate();
    let location = useLocation();

    const urlParams = new URLSearchParams(location.search);
    const tokenFromEmail = urlParams.get("token"); //Query Params i.e. token from email link

    const tokenValidationHandler = useCallback(() => {
        try {
            jwt_decode(tokenFromEmail);
        } catch (error) {
            navigate("/");
        }
    },[navigate, tokenFromEmail]);

    useEffect(() => {
        if (!tokenFromEmail) {
            navigate("/");
        } else {
            tokenValidationHandler();
        }
    }, [tokenFromEmail,navigate,tokenValidationHandler]);

    const resetPasswordHandler = async (payload) => {
        setShowLoading(true);
        const parsePayload = {
            ...payload,
            cPassword: payload.cpassword,
            token: tokenFromEmail.toString(),
        };
        const resp = await resetPasswordAPI(parsePayload);

        if (resp && resp.status) {
            notifSuccess("Reset Password", resp.msg);
            navigate("/");
        } else {
            setShowLoading(false);
            notifError(
                "Reset Password",
                resp.error || resp.msg?.msg[0] || "Something went wrong."
            );
        }
    };

    const customSection = (
        <div className="bottom-section">
            <div className="m-t-5">
                <Button onClick={() => {}} labelTxt="Submit" />
            </div>
            <div className="m-t-5">
                <Link to="/">Back to sign in</Link>
            </div>
        </div>
    );

    return (
        <>
            {showLoading && <Loader />}
            <div className="rpassword-root">
               
                <div className="rpassword-root__right-container">
                    <div className="rpassword-container">
                        <div className="m-b-5">
                            <h1>Reset password?</h1>
                        </div>

                        <FormContainer
                            fields={fields}
                            onSubmit={resetPasswordHandler}
                            initialValues={{}}
                            renderCustomSubmit={customSection}
                        />
                    </div>
                </div>
                <div className="rpassword-root__left-container">
                    <BannerImg />
                </div>
            </div>
        </>
    );
};

export default ResetPassword;
