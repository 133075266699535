import React, { useState, useEffect, useCallback, useContext } from "react";
import { Table, Button, Tooltip, Modal, Spin, Pagination } from "antd";
import "./style.css";
import Skeleton from "react-loading-skeleton";
import { EyeFilled,DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Delete from "assets/images/delete.png"
import { SimpleButton } from "components/button";
import { HotelContext } from "context";
import { useMediaQuery } from "react-responsive";

const ButtonContainer = ({ tooltip, link,labelText,className, ...rest }) => (
    <Link to={link}>
        <Tooltip destroyTooltipOnHide placement="bottom" title={tooltip}>
            <Button {...rest} className={className} >{labelText}</Button>
        </Tooltip>
    </Link>
);

const Action = ({ id, onDeleteRequest, editable, removable, showViewBtn, labelText, isDesktopOrLaptop }) => (
    <>
        <div style={{display:"flex"}}>
        {showViewBtn && (
            <ButtonContainer
                className={"view-btn"}
                link={`view/${id}`}
                tooltip="View Details"
                icon={<EyeFilled className="eye-filled"/>}
            />
        )}
        {removable && (
            <ButtonContainer
                link={""}
                className={isDesktopOrLaptop? "cancel_ride": 'outlineDel'}
                onClick={onDeleteRequest}
                tooltip={"Cancel Ride"}
                icon={!isDesktopOrLaptop && <DeleteOutlined/>}
                labelText={isDesktopOrLaptop && labelText}
            />
        )}
        </div>
    </>
); 

const Container = ({
    columns = [],
    dataLoader = () => [],
    // dataLoadByFilter=()=>[],
    deleteRow,
    parser = () => [],
    removable=true,
    editable = true,
    name,
    searchVal,
    dateString={},
    filterData,
    showViewBtn,
    fetchDataToExcel = () =>{},
}) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 556px)",
      });
    const { hotelProfile } = useContext(HotelContext);
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [removing, setRemoving] = useState(false);
    const [total, setTotal] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const onDeleteRequest = useCallback((id) => () => setAlert(id), [data]);

    const onDelectConirm = useCallback(async () => {
        if (typeof deleteRow === "function") {
            const id = alert;
            setRemoving(true);
            const resp = await deleteRow(id, hotelProfile?.default_lang);
            if (resp.status) {
                setData((d) =>
                        d.filter((i) => i.id !== id).map((row) => ({
                        ...row,
                        key: row.id,
                        action: (
                            <Action
                                id={row.id}
                                removable={removable}
                                editable={true}
                                labelText={isDesktopOrLaptop && "Cancel Ride"}
                                onDeleteRequest={onDeleteRequest(row.id)}
                                showViewBtn={showViewBtn}
                                isDesktopOrLaptop={isDesktopOrLaptop}
                            />
                        ),
                    }))
                );
                setAlert(false);
            }
            setRemoving(false);
        }
    }, [alert, removing]);
 
    const loadData = useCallback(
        async (page = 1, searchVal="", filterData="") => {
            setLoading(true);
            const resp = await dataLoader(page, hotelProfile?.default_lang, searchVal, filterData, dateString.start, dateString.end);
            setTotal(resp.data?.total || 0);
            fetchDataToExcel(parser(resp));
            setCurrentPage(page);
            const data = parser(resp).map((row) => {
                return ({
                ...row,
                key: row.id,
                action: (
                    <Action
                        id={row.id}
                        removable={row.status==="PICKEDUP" ? null : removable}
                        editable={true}
                        labelText={isDesktopOrLaptop && "Cancel Ride"}
                        onDeleteRequest={onDeleteRequest(row.id)}
                        showViewBtn={showViewBtn}
                        isDesktopOrLaptop={isDesktopOrLaptop}
                    />
                ),
            })});
            setData(data);
            setLoading(false);
        },
        [data, editable, removable, dataLoader, dateString]
    );

    const  { start, end } = dateString||{}

    useEffect(() => {
        loadData(currentPage, searchVal, filterData);
    }, [searchVal, filterData, start, end]);
  
    const onChange = (page) => {
        loadData(page, searchVal, filterData)
    };

    if (loading) return <Skeleton count={10} height={30} duration={1} />;
   
    return (
        <>
            <Modal
                visible={Boolean(alert)}
                onOk={onDelectConirm}
                onCancel={() => setAlert(false)}
                closable={false}
                centered
                footer={[
                    <SimpleButton 
                        labelTxt="Cancel"
                        className={!removing ? "cancel-btn": "disable-cancel-btn" }
                        onClick={() => setAlert(false)}
                        disable={removing? true: false}
                    />,
                    <SimpleButton 
                        labelTxt="Delete"
                        className={!removing? "del-btn": "disable-del-btn"}
                        onClick={onDelectConirm}
                        disable={removing? true: false}
                    />
                ]}
            >
                {removing ? (
                    <>
                    <div className="loader">
                        Please Wait... <Spin />
                    </div>
                    </>
                ) : (
                   <div>
                       <div className="del-model">
                           <img src={Delete} className="remove-img"/>
                       </div>
                       <div className="del-model_bottom_content">
                           <h1>Are you sure?</h1>
                           <h4>Hey there are you sure you want to cancel the rides?</h4>
                       </div>
                   </div>
                )}
            </Modal>
            <></>
            <Table columns={columns} dataSource={data} pagination={false} size="small"/>
            <div className="pagination-root">
                <Pagination
                    onChange={onChange}
                    total={total}
                    current={currentPage}
                    defaultCurrent={currentPage}
                    showSizeChanger={false}
                />
            </div>
        </>
    );
};

export default Container;
