import React, { useState } from "react";

import "./index.css";

const FloatLabel = (props) => {
  const [focus, setFocus] = useState(false);
  const { children, label, value } = props;
  const labelClass = focus || value ? "label label-float" : "label";
  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <div id={"zapp-input"}>
        {children}
        <label className={labelClass}>{label}</label>
      </div>
    </div>
  );
};
export default FloatLabel;
