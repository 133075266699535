import { BsArrowLeftRight } from "react-icons/bs";
import { IconContext } from "react-icons";
import "./index.css"
import { memo} from "react";

const InterChange = memo(({ form, field, labelText, options, className,...rest}) => {
    const handleInterChangeClick =() => {
        form.setFieldValue(field.name, !field.value)
        form.setFieldValue("from", form.values["dest"]);
        form.setFieldValue("dest", form.values["from"]);
    };


    return (
      <div className={"inter_change"} >
        {labelText && <span>{labelText}</span>}
        <IconContext.Provider
          value={{
            className: field.value
              ? "BsArrowLeftRightActive"
              : "BsArrowLeftRight",
          }}
        >
          <BsArrowLeftRight
            style={{ cursor: "pointer" }}
            onClick={handleInterChangeClick}
          />
        </IconContext.Provider>
    </div>
    );
  });
  
  export default InterChange;