import Request from "../request";

export const signinAPI = (data) => {
  const resp = Request({
    method: "POST",
    api: "hotel/login",
    data,
  });

  return resp;
};

export const forgotPasswordAPI = (data) => {
  const resp = Request({
    method: "POST",
    api: "hotel/reset-password/link",
    data,
  });

  return resp;
};

export const resetPasswordAPI = (data) => {
  const resp = Request({
    method: "POST",
    api: "hotel/reset-password",
    data,
  });

  return resp;
};
