import { InputField } from "components";

const fields = [
    {
        name: "email",
        // placeholder: "Email",
        type: "email",
        label: "Your email",
        required: true,
        component: InputField,
    },
];

export { fields };
