import React, { useEffect } from "react";
import { Switch } from "antd";

import "./style.css";

export default ({ form, field, labelText, defaultChecked,checkedChildren,unCheckedChildren, className }) => {
    
    useEffect(()=>{
        if(field.value ==false){
            form.setFieldValue("returnDate", '')
        }
    },[field.value])
    return (
        <div className={className}>
            <div className={`label-text`}>{labelText}</div>
            <Switch
                checkedChildren={checkedChildren}
                unCheckedChildren={unCheckedChildren}
                defaultChecked={defaultChecked}
                checked={field.value}
                onChange={(val) => form.setFieldValue(field.name, val)}
            />
        </div>
    );
};
