import React from "react";
import "./style.css";
import { Button as AntButton, Tooltip, Spin } from "antd";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined ,LoadingOutlined} from "@ant-design/icons";

const Button = ({
    onClick,
    labelTxt,
    className,
    disable = false,
    fullWidth = false,
    id = "btn",
    type = "submit",
}) => {
    return (
        <div id={id}>
            <button
                onClick={onClick}
                type={type}
                // disabled
                className={`${
                    fullWidth ? `fullwidth-btn` : `filled-btn`
                } ${className}`}
                disabled={disable}
            >
                {labelTxt}
            </button>
        </div>
    );
};

export default Button;

const SimpleButton = ({
    onClick,
    labelTxt,
    className,
    disable = false,
    fullWidth = false,
    id = "btn",
    type = "submit",
    loading=false,
    ...rest
}) => {
    return (
        <button
            onClick={onClick}
            type={type}
            disabled={disable}
            className={`${
                fullWidth ? `smp-btn` : `filled-smp-btn`
            } ${className}`}
            {...rest}
        >
            {labelTxt}
        </button>
    );
};

const BackButton = ({ tooltip, link, children, ...rest }) => (
    <Link to={link}>
        <Tooltip placement="bottom" title={tooltip}>
            <AntButton
                {...rest}
                icon={rest.icon ? rest.icon : <ArrowLeftOutlined />}
                className="action-btn"
            >
                {children}
            </AntButton>
        </Tooltip>
    </Link>
);

export { SimpleButton, BackButton };
