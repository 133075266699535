import { Select } from "antd";
import "./style.css";
const { Option } = Select;

const DropDownInput = ({
  form,
  field: { name, value },
  labelText,
  create_err_msg,
  options = [],
  placeholder,
  ...rest
}) => {
  return (
    <>
      <div className={`label-text`}>{labelText}</div>
      <Select
        style={{ width: "100%" }}
        onChange={form.handleChange(name)}
        value={value}
        name={name}
      >
        {options.map((opt, index) => (
          <Option key={index}>{opt}</Option>
        ))}
      </Select>
      {form && form.errors[name] && form.touched[name] && (
        <div className="error-text">
          {create_err_msg ? create_err_msg(value) : form.errors[name]}
        </div>
      )}
    </>
  );
};
export default DropDownInput;
