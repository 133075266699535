import React from "react";

import "./style.css";
import { SearchField, AvatarCard, ProfileCard } from "components";
import { logoutHandler } from "utils";
import { SimpleButton } from "components/button";
import { LogoutOutlined } from "@ant-design/icons";
import { PageHeader } from "antd";

function getPageName() {
    const paths = window.location.pathname.split("/");
    if (paths.length > 1) return paths[1].replace("-", " ");
    return "";
}

const Header = () => {
    return (
        <PageHeader
            // className="site-page-header"
            // onBack={() => null}
            title="Title"
            subTitle="This is a subtitle"
        />
    );
};

export default Header;
