import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import "./style.css";
import { Loader } from "components";
import { forgotPasswordAPI } from "services/api-services/auth";
import { notifError, notifSuccess } from "containers/utils";

const ForgotPasswordSuccess = () => {
    const [showLoading, setShowLoading] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const [msg, setMsg] = useState("");

    let navigate = useNavigate();
    let { state } = useLocation();

    useEffect(() => {
        if (state?.email && state?.message) {
            setUserEmail(state.email);
            setMsg(state.message);
        } else {
            navigate("/forgot-password");
        }
    }, []);

    const resendEmailHandler = async () => {
        if (userEmail && msg) {
            setShowLoading(true);
            const resp = await forgotPasswordAPI({ email: userEmail });
            if (resp && resp.status) {
                setShowLoading(false);
                notifSuccess("Resend Link", resp.msg);
            } else {
                setShowLoading(false);
                notifError(
                    "Resend Link",
                    resp.error || resp.msg?.msg[0] || "Something went wrong."
                );
            }
        } else {
            navigate("/forgot-password");
        }
    };

    return (
        <>
            {showLoading && <Loader />}
            <div className="success-root">
                <div className="success-container">
                    <div className="m-b-5">
                        <h2 className="success-container-heading">{msg}</h2>
                    </div>
                    <div>
                        <span className="primary-color font-md">
                            Did not Recieved?
                        </span>
                        <span className="m-l-1">
                            <Link to="#" onClick={(resendEmailHandler)}>
                                Resend
                            </Link>
                        </span>
                    </div>
                    <div className="flex-row link-container m-t-10">
                        <span className="primary-color font-md">Recieved?</span>
                        <span className="m-l-1">
                            <Link to="/"> Sign In</Link>
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ForgotPasswordSuccess;
