import React, { useEffect, useState } from "react";
import { Select } from "antd";

import "./style.css";

const { Option } = Select;
export default ({
    defaultSelectedValue,
    suffixIcon,
    onChange,
    placeholder,
    labelText,
    options,
    variant,
}) => {
    const [val, setVal] = useState(defaultSelectedValue);

    const selectHandler = (val) => {
        setVal(val);
        onChange(val);
    };

    // useEffect(() => {
    //     setVal(defaultSelectedValue);
    // }, [defaultSelectedValue]);

    return (
        <>
            <div className="select-container">
                <Select
                    style={{ width: "100%" }}
                    value={val?.toString()}
                    onChange={selectHandler}
                    placeholder={placeholder}
                    // suffixIcon={
                    //     suffixIcon ? (
                    //         suffixIcon
                    //     ) : (
                    //         <img
                    //             src={ArrowDownBg}
                    //             alt="edit"
                    //             className="icon"
                    //         />
                    //     )
                    // }
                >
                    {options &&
                        options.length > 0 &&
                        options.map((option) => (
                            <Option key={option.id}>{option.label}</Option>
                        ))}
                </Select>
            </div>
        </>
    );
};
