import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import "react-notifications-component/dist/theme.css";
import React, { Suspense } from "react";
import "./App.css";
import {
  Signin,
  ForgotPassword,
  ForgotPasswordSuccess,
  ResetPassword,
  VerifySuccess,
  VerifyError,
} from "containers/auth";
import {
  UpComingRides,
  RideHistory,
  BookTaxi,
  View,
  ViewUpComing,
  CalculatePrice,
  AdminProfile,
  UpComingParkAndFlyRides,
  ViewUpComingParkAndFly,
  ParkAndFly,
  ViewParkAndFly,
  FlyByRideBook,
} from "containers/dashboard";
import SideBar from "./components/sidebar";
import { PrivateRoute } from "components";
import Header from "./components/header";
import "react-loading-skeleton/dist/skeleton.css";
import bookTaxi from "assets/Icons/Book-Taxi.png";
import ridehistory from "assets/Icons/ride-history.png";
import upcomingride from "assets/Icons/upcoming-ride.png";
import calculation from "assets/Icons/calculation.png";
import flyBy from "assets/Icons/flyby.png";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 60, color: "#80b04d" }} spin />
);
const menu = [
  {
    label: "Book a Taxi",
    to: "/app",
    icon: <img src={bookTaxi} className="icon" />,
  },
  {
    label: "Upcoming rides",
    to: "/app/upcoming-ride",
    icon: <img src={upcomingride} className="icon" />,
  },
  {
    label: "Ride History",
    to: "/app/ride-history",
    icon: <img src={ridehistory} className="icon" />,
  },
  // {
  //   label: "Calculate Price",
  //   to: "/app/calculate-price",
  //   icon: <img src={calculation} className="icon" />,
  // },
  {
    label: "Upcoming Park & Fly",
    to: "/app/upcoming-park-and-fly-ride",
    icon: <img src={upcomingride} className="icon" />,
  },
  {
    label: "Park & Fly History",
    to: "/app/park-and-fly-history",
    icon: <img src={flyBy} className="icon" />,
  },
];

const InsideApp = () => (
  <SideBar header={<Header />} menu={menu}>
    <Outlet />
  </SideBar>
);

const App = () => {
  return (
    <Suspense fallback={<Spin indicator={antIcon} className="fallback" />}>
      <Router>
        <Routes>
          <Route path="/" element={<Signin />} />
          <Route path="/forgot-password" element={<ForgotPassword />} exact />
          <Route
            path="/forgot-password-success"
            element={<ForgotPasswordSuccess />}
            exact
          />
          <Route path="/reset-password" element={<ResetPassword />} exact />
          <Route path="/verify-success" element={<VerifySuccess />} exact />
          <Route path="/verify-error" element={<VerifyError />} exact />
          <Route
            path="app"
            element={
              <PrivateRoute>
                <InsideApp />
              </PrivateRoute>
            }
          >
            <Route path="" element={<BookTaxi />} />

            <Route path="setting" element={<AdminProfile />} />

            <Route path="upcoming-ride" element={<Outlet />}>
              <Route path="" element={<UpComingRides />} />
              <Route path="view/:id" element={<ViewUpComing />} />
            </Route>

            <Route path="ride-history" element={<Outlet />}>
              <Route path="" element={<RideHistory />} />
              <Route path="view/:id" element={<View />} />
            </Route>

            {/* <Route path="calculate-price" element={<Outlet />}>
              <Route path="" element={<CalculatePrice />}/>
            </Route>
             */}
            <Route path="upcoming-park-and-fly-ride" element={<Outlet />}>
              <Route path="" element={<UpComingParkAndFlyRides />} />
              <Route path="view/:id" element={<ViewUpComing />} />
            </Route>
            <Route path="park-and-fly-history" element={<Outlet />}>
              <Route path="" element={<ParkAndFly />} />
              <Route path="view/:id" element={<ViewUpComingParkAndFly />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </Suspense>
  );
};

export default () => <App />;
