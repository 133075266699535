import React from "react";
import { Row, Button, Tooltip, Steps } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import "./style.css";
const { Step } = Steps;

const ButtonContainer = ({ tooltip, link, children, className, disabled, ...rest }) => (
    <Link to={link}>
        <Tooltip placement="bottom" title={tooltip}>
            <Button
                {...rest}
                className={`actions-btn ${disabled && "disable-action-btn"} ${className && className}`}
            >
                {children}
            </Button>
        </Tooltip>
    </Link>
);

export const StepperContainer = ({ loading, steps = [], onDone }) => {
    const [current, setCurrent] = React.useState(0);

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const onNext = () => {
        if (steps[current].formRef) {
            const form = steps[current].formRef.current;
            if (form.isValid) {
                form.handleSubmit();
                if (form.dirty) return next();
            }
        } 
        else {
            next();
        }
    };

    const save = () => {
        setCurrent(steps.length - 1);
        if (steps[current].formRef) {
            const form = steps[current].formRef.current;
            if (form.isValid) {
                form.handleSubmit();
                return onDone();
            }
        }
        if (onDone){ 
            return onDone()};
    };

    return (
        <>
            <div className="stepper-style">
                <Steps
                    current={current}
                    labelPlacement="vertical"
                    size="small"
                >
                    {steps.map((item) => (
                        <>
                            <Step key={item.title} title={item.title} />
                        </>
                    ))}
                </Steps>
            </div>
            <div className="stepper-container">
                {steps.map((item, index) => (
                    <div
                        key={index}
                        className={
                            current === index ? "steps-content" : "step-hide"
                        }
                    >
                        {item.content}
                    </div>
                ))}
            </div>
            <div>
                <Row className="btn-bottom-space" justify="center">
                    {current >0 && 
                    <ButtonContainer
                        tooltip="Go Back"
                        link="#"
                        onClick={current > 0 && prev}
                        size="large"
                        // className="back"
                        disabled={loading}
                    >
                        BACK
                    </ButtonContainer>
                    }
                    {current <= steps.length - 2 ? (
                        <ButtonContainer
                            tooltip="Go Next"
                            link="#"
                            type="primary"
                            size="large"
                            onClick={onNext}
                            // className="next"
                            disabled={loading || steps[current].disabled}
                        >
                            NEXT
                        </ButtonContainer>
                    ) : (
                        <ButtonContainer
                            tooltip="Save"
                            link="#"
                            type="primary"
                            size="large"
                            onClick={save}
                            // className="next"
                            disabled={loading}
                        >
                            SAVE
                        </ButtonContainer>
                    )}
                </Row>
            </div>
        </>
    );
};
