import React, { useEffect, useState, useCallback, useContext,useMemo } from "react";
import {
  Drawer,
  Menu,
  Button,
  PageHeader,
  Popover,
  Row,
  Col,
  Space,
  Spin,
} from "antd";
import { Link } from "react-router-dom";
import {
  IdcardOutlined,
  HomeOutlined,
  CarOutlined,
  UsergroupAddOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import "./style.css";
import { APP_NAME } from "constants";
import { useMediaQuery } from "react-responsive";
import { getAdminProfileAPI } from "services/api-services/profile";
import { logoutHandler } from "utils";
import Logo from "assets/images/logo.png";
import Picture from "assets/images/user.png";
import Setting from "assets/Icons/setting.png";
import { useLocation, useNavigate } from "react-router-dom";
import { HotelContext } from "context";

const drawerWidth = 165;

const activeSideBar = (menu) => {
  console.log('SIDE BAR-')
  for (let i = 0; i < menu.length; i++) {
    if (
      (menu[i].to.startsWith(window.location.pathname) &&
        menu[i].to.endsWith(window.location.pathname)) ||
      (menu[i].nested || []).indexOf(window.location.pathname) > -1
    ) {
      return [`${i}`];
    }
  }
  return ["0"];
};

export const getlabel = (menu = []) => {
  if (!menu.length) return "Zapp";
  const active = activeSideBar(menu);
  return menu[Number(active[0])].label;
};

const SideBar = ({ header, children, menu = [] }) => {
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [label, setLabel] = useState(getlabel(menu));
  const location = useLocation();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 768px)",
  });
  const [visible, setVisible] = useState(false);
  const [hotelDetail, setHotelDetail] = useState();
  const { setHotelProfile } = useContext(HotelContext);

  const loadHotalLocation = async () => {
    const resp = await getAdminProfileAPI();
    setHotelDetail(resp.data);
    setHotelProfile(resp.data);
  };

  useEffect(() => {
    loadHotalLocation();
  }, []);

 
 
  const toggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };

  const hide = () => {
    setVisible(false);
  };

  useEffect(() => {
    setIsDrawerOpen(isDesktopOrLaptop);
  }, [isDesktopOrLaptop]);


  const renderMenuItem = useCallback(
    (item, index) => (
      <Menu.Item
        onClick={() => {
          setLabel(item.label);
          !isDesktopOrLaptop && toggle();
        }}
        key={index}
        icon={item.icon ? item.icon : null}
      >
        <Link to={item.to}>{item.label}</Link>
      </Menu.Item>
    ),
    [menu, isDesktopOrLaptop]
  );
  const activeKey = () => {
    console.log('METHOD 1')
    if (location.pathname.includes("app/upcoming-ride/view")) {
      setLabel("By Upcoming Rides");
      return ["1"];
    }
    if (location.pathname.includes("app/ride-history/view")) {
      setLabel("Ride History");
      return ["2"];
    }
  };
  const activeMenu = useMemo(() => {
    const active = activeSideBar(menu)
    setLabel(menu[Number(active[0])].label)
    return active
  }, [menu, label, location.pathname])

  const content = (
    <Space direction="vertical">
      <Row onClick={hide} key="2">
        <Link key="2" to="setting" className="a-link">
          Settings
        </Link>
      </Row>
    </Space>
  );

  const logOutContent = (
    <Space direction="vertical">
      <Row onClick={hide} key="1">
        <Link key="1" to="#" onClick={logoutHandler} className="a-link">
          Logout
        </Link>
      </Row>
    </Space>
  );
  return (
    <div id="sidebar">
      <div
        className="content-wrapper"
        style={{ marginLeft: isDesktopOrLaptop ? drawerWidth : 0 }}
      >
        <PageHeader
          backIcon={!isDesktopOrLaptop && <MenuOutlined></MenuOutlined>}
          title={label} 
          onBack={!isDesktopOrLaptop && toggle}
          extra={[
            <div>
              <Button
                className="setting-btn"
                onClick={() => navigate("/app/setting")}
              >
                <img className="setting" src={Setting} alt="Logo" />
              </Button>
              {/* <img
                  className="notification"
                  src={Notification}
                  alt="Notificaions"
                /> */}
            </div>,
            <Popover
              placement="topLeft"
              content={logOutContent}
              trigger="click"
              visible={visible}
              onVisibleChange={handleVisibleChange}
            >
              {" "}
              {hotelDetail ? (
                <img
                  className="profile"
                  src={hotelDetail.logo !== null ? hotelDetail.logo : Picture}
                />
              ) : (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 24,
                        margin:"3px 9px 0px 0px"
                      }}
                      spin
                    />
                  }
                />
              )}
            </Popover>,
          ]}
        />
        <div>{children}</div>
      </div>
      <Drawer
        placement="left"
        closable={!isDesktopOrLaptop}
        mask={!isDesktopOrLaptop}
        visible={isDrawerOpen}
        onClose={toggle}
        key="left"
        autoFocus={false}
        bodyStyle={{}}
        className="drawer-root__container"
        contentWrapperStyle={{ boxShadow: "none" }}
        width="165px"
      >
    
        <div className="app-name">
          <img src={Logo} alt="Logo" className="zapp-logo" />
        </div>

        <Menu
          defaultSelectedKeys={
            location.pathname.includes("edit") ||
            location.pathname.includes("view") ||
            location.pathname.includes("add")
              ? activeKey
              : activeMenu
          }
          mode="inline"
          selectedKeys={location.pathname.includes("edit") ||
          location.pathname.includes("view") ||
          location.pathname.includes("add")
            ? activeKey
            : activeMenu}
        >
          {menu.map(renderMenuItem)}
        </Menu>
      </Drawer>
    </div>
  );
};

export default SideBar;
