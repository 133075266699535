import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./style.css";
import BannerImg from "components/banner-img";
import { fields } from "./fields";
import { FormContainer, Button, Loader } from "components";
import { forgotPasswordAPI } from "services/api-services/auth";
import { notifError } from "containers/utils";
import { checkLoggedIn } from "utils";
import { message } from "antd";
const ForgotPassword = () => {
    let navigate = useNavigate();
    const [showLoading, setShowLoading] = useState(false);


    useEffect(() => {
        let isLoggedIn = checkLoggedIn();
        if (isLoggedIn) navigate("/hotel");
    }, [navigate]);

    const forgotPasswordHandler = async (payload) => {
        setShowLoading(true);
        const resp = await forgotPasswordAPI(payload);
        if (resp && resp.status) {
            setShowLoading(false);
            navigate("/forgot-password-success", {
                state: { ...payload, message: resp.msg },
            });
        } else {
            setShowLoading(false);
            notifError(
                "Forgot Password",
                resp.error || resp.msg?.msg[0] || "Something went wrong."
            );
        }
    };

    const customSection = (
        <div className="bottom-section-forgot-password">
            <div className="m-t-1">
                <Button onClick={() => {}} labelTxt="Submit" className={"Submit-handler"}/>
            </div>
            <div className="m-t-1">
                <Button onClick={()=>navigate("/")} labelTxt="Back to sign in" className="navigate-handler"/>
            </div>
        </div>
    );

    return (
        <>
            {showLoading && <Loader />}
            <div className="fpassword-root">
                <div className="fpassword-root__right-container">
                    <div className="fpassword-container">
                    <div className="header">Welcome to Zapp!</div>
                        <div className="m-b-5">
                            <h1>Forgot password?</h1>
                            <div className="primary-color fpassword-container__sub-heading">
                                Enter the email address you used when you joined
                                and we'll send you instructions to reset your
                                password.
                            </div>
                        </div>

                        <FormContainer
                            fields={fields}
                            onSubmit={forgotPasswordHandler}
                            initialValues={{}}
                            renderCustomSubmit={customSection}
                        />
                    </div>
                </div>
                    <div className="fpassword-root__left-container">
                        <BannerImg />
                    </div>
            </div>
        </>
    );
};

export default ForgotPassword;
